import React from "react"
import { graphql } from "gatsby"
import { H1, PX3, Max2xl, Max5xl } from "../components/blocks"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostIndex } from "../components/post"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.posts.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog Posts" />
        <Max5xl className="h-full">
          <Max2xl className="h-full">
            <PX3>
              <H1>Blog</H1>
              <PostIndex posts={posts} />
            </PX3>
          </Max2xl>
        </Max5xl>
      </Layout>
    )
  }
}

export default BlogIndex

export const blogQuery = graphql`
  query blogSites {
    site {
      siteMetadata {
        title
      }
    }

    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**/*.md" }
        frontmatter: { state: { eq: "current" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...PostIndexInformation
    }
  }
`
